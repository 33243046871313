import { html, TemplateResult, nothing } from "lit-html";
import { IMenuData, IMenuItem, IMenuSettings } from "../cms-menu.js";

export function main(data: IMenuData, settings: IMenuSettings) : TemplateResult {
    return html`<div class="menu level-1">
        <ul>${data.children.map(i => item(i, settings))}</ul>
    </div>`;
}

function item(data: IMenuItem, settings: IMenuSettings) : TemplateResult {
    return html`<li class="${data.selected || data.expanded ? ((data.selected ? "selected " : "") + (data.expanded ? "expanded" : "")).trim() : nothing}">
        <div class="item">
            <a class="${data.hasChildren ?  "has-children": nothing}" href="${data.url}" target=${data.useTargetBlank ? "_blank" : nothing}><span></span>${data.name}</a>
            ${data.hasChildren ?
                html`<button
                        @click=${(e:MouseEvent) => settings.toggle(e, data)}
                        aria-label="${data.expanded ? "Close" : "Expand"}"
                        aria-controls="children_${data.id}"
                        aria-expanded="${data.expanded ? "true" : "false"}">${data.expanded ? "Close" : "Expand"}</button>`
                : nothing}
            ${data.loading ? html`<div class="loading" aria-label="Loading">Loading</div>` : nothing}
        </div>
        ${data.children || data.loading ? html`<div id="children_${data.id}" class="menu level-${data.level + 1}">${data.children ? html`<div class="menu-top"><button @click=${(e:MouseEvent) => settings.toggle(e, data)} aria-label="${data.expanded ? "Close" : "Expand"}" aria-controls="children_${data.id}" aria-expanded="${data.expanded ? "true" : "false"}">${data.expanded ? "Close" : "Expand"}</button><a class="fw-semibold" href="${data.url}" target=${data.useTargetBlank ? "_blank" : nothing}><span></span>${data.name}</a></div><ul>${data.children.map(i => item(i, settings))}</ul>` : nothing}</div>` : nothing}
    </li>`;
}